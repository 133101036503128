import Repository from './repository'

const resource = '/v1/treinamentos'

export default {
  buscaTreinamentoPorId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  listarTreinamentos (search) {
    return !search ? Repository.get(`${resource}`) : Repository.get(`${resource}/busca/${search}`)
  },
  salvarTreinamento (item) {
    return Repository.post(`${resource}`, item)
  },
  atualizarTreinamento (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  deletarTreinamento (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  salvarTreinamentoFuncionarios(funcionarios) {
    return Repository.post(`${resource}/funcionarios`, funcionarios)
  },
  buscaTreinamentoFuncionariosPorTreinamentoId (id) {
    return Repository.get(`${resource}/funcionarios/${id}`)
  },
  gerarFaltaTreinamento(data) {
    return Repository.post(`${resource}/falta`, data)
  },
  buscarFaltasTreinamento(id) {
    return Repository.get(`${resource}/${id}/faltas`)
  },
  buscaTreinamentoPorCodigo (codigo) {
    return Repository.get(`${resource}/codigo/${codigo}`)
  },
  duplicarFuncionariosTreinamento (origem, destino) {
    return Repository.get(`${resource}/funcionarios/copiar/${origem}/${destino}`)
  },
}
