<template>
  <div class="container-fluid">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>
          Relatório de faltas
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="nome">Treinamento</label>
          <select2 
            :ItemsProp="treinamentosFiltrado"
            :FieldProp="'nome_treinamento'"
            :PlaceholderTextProp="'Pesquisar treinamento'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="treinamentoSelecionado"
            @searchText="filteredData"
            @Selected="TreinamentoSelecionado($event)">  
          </select2>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="nome">Empresa</label>
          <select2 
            :ItemsProp="empresas"
            :FieldProp="'nome_fantasia'"
            :PlaceholderTextProp="'Pesquisar empresa'"
            :ClearAfterSelectedProp="false"
            :SelectedItemProp="empresaSelecionada"
            @Selected="EmpresaSelecionada($event)">  
          </select2>
        </div>
      </div>
      <div class="mr-2">
        <div class="form-group">
          <label for="">Data início</label>
          <input v-model="filtro.data_inicio" placeholder="00/00/0000" v-mask="'##/##/####'" type="text" class="form-control">
        </div>
      </div>
      <div class="mr-2">
        <div class="form-group">
          <label for="">Data fim</label>
          <input v-model="filtro.data_fim" v-mask="'##/##/####'" placeholder="00/00/0000" type="text" class="form-control">
        </div>
      </div>
      <div class="mr-5 align-self-end">        
        <div class="form-group">
          <button type="button" class="btn btn-primary" @click="gerarRelatorio">GERAR</button>
        </div>
      </div>
    </div>
    <div class="row mt-5"  v-if="dataItems.length > 0">
      <div class="col-12 mb-3 text-right">
        <ExportExcel :data="dataItems" :fields="excelFields" />
      </div>
      <div class="col-12">
        <table class="table table-hover border table-striped table-bordered">
          <thead>
            <td>Evento</td>
            <td>Cliente</td>
            <td>Curso</td>
            <td>Data</td>
            <td>Carga horária</td>
            <td>Colaborador</td>
            <td>CPF</td>
            <td>Dias de falta</td>
            <td>Obs</td>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataItems" :key="index">
              <td>{{item.evento}}</td>
              <td>{{item.matriz_nome_fantasia}}</td>
              <td>{{item.nome_atividade}}</td>
              <td>{{item.data_inicio | maskdateptbr}} à {{item.data_fim | maskdateptbr}}</td>
              <td>{{item.carga_horaria}}</td>
              <td>{{item.funcionario_nome}}</td>              
              <td>{{item.cpf}}</td>
              <td>{{item.faltas.length}}</td>
              <td>{{item.observacao}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="8">
                Total de pessoas faltosas: {{dataItems.length}}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import treinamentoRepository from './../../../services/api/treinamentoRepository'
import relatoriosRepository from './../../../services/api/relatoriosRepository'
import empresaRepository from './../../../services/api/empresaRepository'
import select2 from './../../../uicomponents/select2'
import ExportExcel from './../../../uicomponents/ExportExcel'
export default {
  components: {
    select2,
    ExportExcel
  },
  data () {
    return {
      filtro: {
        empresa_id: null,
        treinamento_id: null,
        data_inicio: '',
        data_fim: ''
      },
      isLoading: false,
      empresaSelecionada: null,
      treinamentoSelecionado: null,
      treinamentos: [],
      treinamentosFiltrado: [],
      empresas: [],
      dataItems: [],
      excelFields: {
        Evento: 'evento',
        Cliente: 'matriz_nome_fantasia',
        Curso: 'nome_atividade',
        Data: 'periodo',
        'Carga horária': 'carga_horaria',
        'Nome colaborador': 'funcionario_nome',
        CPF: 'cpf',
        'Quant. dias de falta': {
          field: 'faltas',
          callback: (value) => {
            return value.length
          }
        },
        Observacao: 'observacao'
      }
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    filteredData (searchText) {
      let filterKey = searchText && searchText.length > 0 && searchText.toLowerCase()
      console.log(this.treinamentos)
      let data = [...this.treinamentos]
      
      if (filterKey) {
        data = data.filter(function (row) {
          if(String(row.codigo).indexOf(filterKey) > -1 || row.nome_atividade.toLowerCase().indexOf(filterKey) > -1) {
            return row
          }
        })
      }
      this.treinamentosFiltrado = [...data]
      console.log(this.treinamentosFiltrado)
      if(this.treinamentosFiltrado.length == 0) {
        this.fetch(filterKey)
      }
    },
    async fetch(search = null) {
      try {
        this.isLoading = true
        console.log(search)
        let responseT = await treinamentoRepository.listarTreinamentos(search)
        if(responseT.data['success']) {
          this.treinamentosFiltrado = this.treinamentos = responseT.data['data'].map(item => {
            item.nome_treinamento = `${item.codigo} - ${item.nome_atividade}`
            return item
          })
          console.log(this.treinamentosFiltrado)
        }
        let responseE = await empresaRepository.listarEmpresas()
        if(responseE.data['success']) {
          this.empresas = responseE.data['data']
        }
      }catch (e) {
        console.log(e)
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Não foi possível buscar os dados básicos de filtro!'
        })
      }finally {
        this.isLoading = false
      }
    },
    TreinamentoSelecionado(treinamento) {
      console.log(treinamento)
      this.treinamentoSelecionado = treinamento
    },
    EmpresaSelecionada(empresa) {
      console.log(empresa)
      this.empresaSelecionada = empresa
    },
    gerarRelatorio() {
      let data = Object.assign({}, this.filtro)
      data.data_inicio = this.filtro.data_inicio != '' ? this.filtro.data_inicio.split('/').reverse().join('-') : null
      data.data_fim = this.filtro.data_fim != '' ? this.filtro.data_fim.split('/').reverse().join('-') : null
      this.dataItems = []
      data.empresa_id = this.empresaSelecionada != null ? this.empresaSelecionada.id : null
      data.treinamento_id = this.treinamentoSelecionado != null ? this.treinamentoSelecionado.id : null
      this.isLoading = true
      relatoriosRepository.treinamentoFaltas(data).then(response => {
        this.isLoading = false
        if(response.data['success']) {
          this.dataItems = response.data['data']
        }
      }).catch(() => {
        this.isLoading = false
        this.$swal({
          icon: 'error',
          text: 'Ocorreu um erro ao gerar o relatório!'
        })
      })
    }
  }
}
</script>

<style>

</style>