import Repository from './repository'

const resource = '/v1/relatorios'

export default {
  treinamentoFaltas(data) {
    return Repository.post(`${resource}/treinamento/faltas`, data)
  },
  treinamento(data) {
    return Repository.post(`${resource}/treinamento`, data)
  },
  treinamentoVencido(data) {
    return Repository.post(`${resource}/treinamento/vencimentos`, data)
  }
}
