<template>
  <div>
    <download-excel
      class="btn btn-sm btn-info"
      :data="dataItems"
      :fields="fieldsItems"
      worksheet="Folha1"
      name="excel.xls"
    >
      Exportar para excel
    </download-excel>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: new Array
    },
    fields: {
      type: Array,
      default: new Array
    }
  },
  mounted () {
    this.dataItems = this.data
    this.fieldsItems = this.fields
  },
  data () {
    return {
      dataItems: [],
      fieldsItems: null
    }
  }
}
</script>

<style>

</style>